import React, { useMemo } from "react";
import styles from './EntriesList.module.sass';
import ScrollableFeedVirtualized from "react-scrollable-feed-virtualized";
import down from "./assets/downImg.svg";
import { useRecoilValue } from "recoil";
import entriesAtom from "../../recoil/entries";
import { TrafficStatsFooter } from "../TrafficViewer/TrafficStatsFooter/TrafficStatsFooter";
import { TrafficEntry } from "../TrafficEntry/TrafficEntry";

interface EntriesListProps {
  listEntryREF: React.LegacyRef<HTMLDivElement>;
  onSnapBrokenEvent: () => void;
  isSnappedToBottom: boolean;
  setIsSnappedToBottom: (state: boolean) => void;
  scrollableRef: React.MutableRefObject<ScrollableFeedVirtualized>;
}

export const EntriesList: React.FC<EntriesListProps> = ({
  listEntryREF,
  onSnapBrokenEvent,
  isSnappedToBottom,
  setIsSnappedToBottom,
  scrollableRef,
}) => {
  const entries = useRecoilValue(entriesAtom);
  const memoizedEntries = useMemo(() => {
    return entries;
  }, [entries]);


  return <React.Fragment>
    <div className={styles.list}>
      <div id="list" ref={listEntryREF} className={styles.list}>
        <ScrollableFeedVirtualized className={styles.scrollableFeed} ref={scrollableRef} itemHeight={52} marginTop={10} onSnapBroken={onSnapBrokenEvent}>
          {false /* It's because the first child is ignored by ScrollableFeedVirtualized */}
          {[...memoizedEntries.values()].map(entry => {
            return <TrafficEntry
              key={entry.key}
              entry={entry}
              duplicateOrigin={
                entry.duplicate !== '' ? entries.get(entry.duplicate) : null
              }
              style={{}}
            />
          })}
        </ScrollableFeedVirtualized>
        <button type="button"
          title="Snap to bottom"
          className={`${styles.btnLive} ${isSnappedToBottom ? styles.hideButton : styles.showButton}`}
          onClick={() => {
            scrollableRef.current.jumpToBottom();
            setIsSnappedToBottom(true);
          }}>
          <img alt="down" src={down} />
        </button>
      </div>
      <TrafficStatsFooter />
    </div>
  </React.Fragment>;
};
