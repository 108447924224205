import React from 'react'
import { Box } from '@mui/material'
import Queryable from '../../UI/Queryable/Queryable'

import { EntryPoint } from '../../../types/trafficEntry'

import styles from '../TrafficEntry.module.sass'
import { EntrySource } from './ResolutionData'

interface IpWithPortProps {
  ip: string
  port: string
  point: EntryPoint
}

export const IpWithPort: React.FC<IpWithPortProps> = ({ ip, port, point }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      width='100px'
      maxWidth='120px'
      position='relative'
      justifyContent={point === EntrySource ? 'flex-end' : 'flex-start'}
    >
      <Queryable
        query={`${point}.ip == "${ip}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={point === EntrySource ? {
          position: 'absolute',
          top: '12px'
        } : {
          position: 'absolute',
          top: '12px',
          right: '50px'
        }}
        tooltipStyle={point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: '25px',
          top: '-5px'
        }}
      >
        <span className={styles.DataText}>{ip}:</span>
      </Queryable>
      <Queryable
        query={`${point}.port == "${port}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={point === EntrySource ? {
          position: 'absolute',
          top: '12px'
        } : {
          position: 'absolute',
          top: '12px',
          right: '30px'
        }}
        tooltipStyle={ point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: 0,
          top: '-5px'
        }}
      >
        <span className={styles.DataText}>{port}</span>
      </Queryable>
    </Box>
  )
}
