import React from 'react'
import { Box } from '@mui/material'
import { EntryData } from './EntryData'
import { formatSize } from '../../EntryDetailed/EntryDetailed'
import variables from '../../../variables.module.scss'

type BandwidthType = 'requestSize' | 'responseSize'

export const RequestBandwidth: BandwidthType = 'requestSize'
export const ResponseBandwidth: BandwidthType = 'responseSize'

interface BandwidthProps {
  type: BandwidthType,
  size: number
}

export const Bandwidth: React.FC<BandwidthProps> = ({ type, size }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      padding='2px 4px'
      borderRadius='4px'
      bgcolor={variables.dataBackgroundColor}
      width='fit-content'
      height='15px'
      maxHeight='15px'
    >
      <EntryData
        name={`${formatSize(size)}`}
        query={`${type} == "${size}"`}
      />
    </Box>
  )
}
