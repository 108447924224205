import { useRecoilValue, useSetRecoilState } from 'recoil'
import frontHealthAtom from '../recoil/healthStatus/frontHealth'
import hubHealthAtom from '../recoil/healthStatus/hubHealth'
import useLicense from './useLicense'
import { LicenseInfo } from '../types/license'
import { LicenseEnterpriseEdition } from '../consts'
import { useEffect } from 'react'
import { InternetConnection, StatusExplanation } from '../types/health'
import hubPublicNetworkOkAtom from '../recoil/hubPublicNetworkOk'
import hubOperationalAtom from '../recoil/hubOperational'

const checkInternetRequired = (licenseInfo: LicenseInfo) => {
  return licenseInfo.doc.edition !== LicenseEnterpriseEdition
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const computeFrontHealth = (internetOk: boolean, licenseInfo: LicenseInfo) => {
  if (internetOk) {
    return true
  }

  return !internetOk && !checkInternetRequired(licenseInfo)
}

const computeHubHealth = (hubOperational: boolean | null, internetOk: boolean | null, licenseInfo: LicenseInfo): boolean | null => {
  if (typeof hubOperational === 'boolean' && !hubOperational) {
    return false
  }

  if (hubOperational === null || internetOk === null) {
    return null
  }

  if (hubOperational && internetOk) {
    return true
  }

  return hubOperational && !internetOk && !checkInternetRequired(licenseInfo)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const explainFrontStatus = (internet: InternetConnection, licenseInfo: LicenseInfo): StatusExplanation | null => {
  if (internet.required && !internet.ok) {
    return {
      title: 'No Internet connection',
      text: `
          Your Kubeshark installation runs under ${licenseInfo.doc.edition} license and requires Internet connection.
          To enable Kubeshark running offline, you need ${LicenseEnterpriseEdition} license. Please, contact us.
        `
    }
  }

  return null
}

const explainHubStatus = (operational: boolean | null, internet: InternetConnection, licenseInfo: LicenseInfo): StatusExplanation | null => {
  if (typeof operational === 'boolean' && !operational) {
    return {
      title: 'Hub is unreachable',
      text: 'Hub service cannot be reached & is unresponsive or down.'
    }
  }

  if (
    internet.required &&
    typeof internet.ok === 'boolean' && !internet.ok &&
    licenseInfo.doc.edition !== LicenseEnterpriseEdition
  ) {
    return {
      title: 'No Internet connection',
      text: `
          Your Kubeshark installation runs under ${licenseInfo.doc.edition} license and requires Internet connection.
          To enable Kubeshark running offline, you need ${LicenseEnterpriseEdition} license.
      `
    }
  }

  return null
}

export const useHealthObserver = () => {
  const hubPublicNetworkOk = useRecoilValue(hubPublicNetworkOkAtom)
  const hubOperational = useRecoilValue(hubOperationalAtom)

  const setFrontHealth = useSetRecoilState(frontHealthAtom)
  const setHubHealth = useSetRecoilState(hubHealthAtom)

  const { licenseInfo } = useLicense()

  useEffect(() => {
    const internet: InternetConnection = {
      ok: true,
      required: true
    }

    setFrontHealth({
      internet,
      healthy: true,
      lastUpdated: new Date()
    })
  }, [])

  useEffect(() => {
    setHubHealth({
      operational: hubOperational,
      internet: {
        ok: hubPublicNetworkOk,
        required: checkInternetRequired(licenseInfo)
      },
      healthy: computeHubHealth(hubOperational, hubPublicNetworkOk, licenseInfo),
      explanation: explainHubStatus(
        hubOperational,
        {ok: hubPublicNetworkOk, required: checkInternetRequired(licenseInfo)},
        licenseInfo
      ),
      lastUpdated: new Date()
    })
  }, [hubOperational, hubPublicNetworkOk, licenseInfo])
}
