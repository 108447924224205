import { Box, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import styles from './LicenseBadge.module.sass'
import variables from '../../../../variables.module.scss'

import KeyIcon from '@mui/icons-material/Key'
import LoopIcon from '@mui/icons-material/Loop';
import { appCloudLicenseEnabled } from '../../../../types/global'
import useLicense from '../../../../hooks/useLicense'
import { LicenseEnterpriseEdition, LicenseProEdition } from '../../../../consts'
import { Utils } from '../../../../helpers/Utils'
import { useRecoilValue } from 'recoil'
import { internetConnectionSelector } from '../../../../recoil/healthStatus/atom'

interface LicenseBadgeProps {
  dropdownOpen: boolean
  setDropdownOpen: (state: boolean) => void
}

export const LicenseBadge: React.FC<LicenseBadgeProps> = ({
  dropdownOpen,
  setDropdownOpen,
}) => {
  const {ready, licenseInfo, commercialEdition, valid, apiCapacityDepleted, showApiCapacityProgress} = useLicense()

  const [badgeTitle, setBadgeTitle] = useState('')

  const internetConnection = useRecoilValue(internetConnectionSelector)

  useEffect(() => {
    let title = ''

    if (!ready) {
      title = 'Loading'
    } else if (!internetConnection && licenseInfo.doc.edition !== LicenseEnterpriseEdition) {
      title = 'No Internet'
    } else if (appCloudLicenseEnabled() && !commercialEdition) {
      title = 'Not detected'
    } else {
      title = licenseInfo.doc.edition
    }

    setBadgeTitle(title)
  }, [ready, licenseInfo, commercialEdition])

  const [bgColor, setBgColor] = useState(variables.blueColor)

  useEffect(() => {
    if (!ready) {
      return
    }

    let badgeBgColor = ''

    if (!valid || (apiCapacityDepleted && licenseInfo.doc.edition === LicenseProEdition)) {
      badgeBgColor = variables.upgradeColor
    } else if (showApiCapacityProgress && licenseInfo.doc.edition === LicenseProEdition) {
      const capacityPercentage = licenseInfo.doc.capacity / licenseInfo.doc.initialCapacity * 100
      badgeBgColor = Utils.getColorByPercentage(capacityPercentage)
    } else if (appCloudLicenseEnabled() && commercialEdition) {
      badgeBgColor = variables.successColor
    } else {
      badgeBgColor = variables.blueColor
    }

    setBgColor(badgeBgColor)
  }, [ready, licenseInfo, valid])

  return (
    <Tooltip title='See edition details' placement='top-start' arrow>
      <Box
        className={styles.LicenseBadge}
        bgcolor={ready ? bgColor : variables.grayColor}
        onClick={() => ready && setDropdownOpen(!dropdownOpen)}
      >
        {ready ? (
          <KeyIcon
            htmlColor={variables.mainBackgroundColor}
            sx={{ fontSize: 11 }}
          />
        ) : (
          <LoopIcon
            htmlColor={variables.mainBackgroundColor}
            sx={{ fontSize: 11 }}
          />
        )}
        <Typography
          variant='body2'
          fontSize={11}
          color={variables.mainBackgroundColor}
          fontFamily='Roboto'
          fontWeight={600}
          whiteSpace='nowrap'
          lineHeight='1.2'
          textTransform='uppercase'
        >
          {badgeTitle}
        </Typography>
      </Box>
    </Tooltip>
  )
}
