import React from 'react'

import Queryable from '../../UI/Queryable/Queryable'

import Moment from 'moment'
import { Utils } from '../../../helpers/Utils'

import styles from '../TrafficEntry.module.sass'
import { Box } from '@mui/material'

interface TimestampProps {
  timezone: string
  unixTimestamp: Date
}

export const Timestamp: React.FC<TimestampProps> = ({
  timezone,
  unixTimestamp
}) => {
  return (
    <Box className={styles.timestamp} height='100%' pl='20px' mr='20px' position='relative'>
      <Queryable
        query={`timestamp >= datetime("${Moment(+unixTimestamp)?.utc().format('MM/DD/YYYY, h:mm:ss.SSS A')}")`}
        queryNote='this filter is applied in UTC'
        displayIconOnMouseOver={true}
        flipped={false}
        iconStyle={{
          position: 'absolute',
          left: 0,
        }}
        tooltipStyle={{
          position: 'absolute',
          left: 0,
        }}
      >
        <span
          title={`Timestamp in UTC${Utils.TimezoneMoment(timezone).format('Z')}`}
          className={styles.DataText}
        >
          {Utils.TimezoneMoment(timezone, +unixTimestamp)?.format('hh:mm:ss.SSS A UTCZ')}
        </span>
      </Queryable>
    </Box>
  )
}
