import { useEffect } from "react";
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from "../recoil/trafficPlayback/atom";
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAuth } from "../components/UI/Auth/Auth";
import { HubBaseUrl } from "../consts";
import entriesCapturedAtom from "../recoil/entriesCaptured/atom";
import sizeCapturedAtom from "../recoil/sizeCaptured/atom";
import lastUpdatedAtom from "../recoil/lastUpdated";

interface TrafficStats {
  entriesCaptured: number
  setEntriesCaptured: (captured: number) => void
  sizeCaptured: string
  lastUpdated: number
  setLastUpdated: (unixMs: number) => void
  fetchPcapsTotalSize: () => void
}

const useTrafficStats = (): TrafficStats => {
  const [entriesCaptured, setEntriesCaptured] = useRecoilState(entriesCapturedAtom);
  const [sizeCaptured, setSizeCaptured] = useRecoilState(sizeCapturedAtom);
  const [lastUpdated, setLastUpdated] = useRecoilState(lastUpdatedAtom)

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { shouldAuthenticate } = useAuth()

  const fetchPcapsTotalSize = () => {
    fetch(`${HubBaseUrl}/pcaps/total-size`)
      .then(response => {
        return response.text();
      })
      .then(rawJson => {
        return rawJson ? JSON.parse(rawJson) : {}
      })
      .then(data => {
        setSizeCaptured(data.total)
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchPcapsTotalSize()

    const fetchInterval = setInterval(() => {
      if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
        return
      }

      if (shouldAuthenticate) {
        return
      }

      fetchPcapsTotalSize()
    }, 3000)

    return () => clearInterval(fetchInterval)
  }, [trafficPlayback, shouldAuthenticate])

  return {entriesCaptured, setEntriesCaptured, sizeCaptured, lastUpdated, setLastUpdated, fetchPcapsTotalSize}
}

export default useTrafficStats
