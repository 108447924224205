import { useEffect, useState } from 'react'
import {
  LicenseApiCapacityOption,
  LicenseExpiryOption,
  LicenseInfo
} from '../types/license'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import nodeLimitOverflowAtom from '../recoil/nodeLimitOverflow/atom'
import licenseInfoAtom from '../recoil/licenseInfo'
import {
  HubBaseUrl,
  LicenseCommunityEdition,
  LicenseEnterpriseEdition,
  LicenseOndemandEdition,
  LicenseProCompEdition,
  LicenseProEdition,
  LicenseProUnlEdition
} from '../consts'
import { toast } from 'react-toastify'
import hubOperationalAtom from '../recoil/hubOperational'
import { internetConnectionSelector } from '../recoil/healthStatus/atom'
import { Utils } from '../helpers/Utils'
import hubPublicNetworkOkAtom from '../recoil/hubPublicNetworkOk'
import { defaultLicenseInfo } from '../recoil/licenseInfo/atom'

interface LicenseInfoState {
  ready: boolean
  licenseInfo: LicenseInfo
  nodeLimitOverflow: boolean
  setNodeLimitOverflow: (isOverflow: boolean) => void
  expired: boolean
  valid: boolean
  showApiCapacityProgress: boolean
  apiCapacityDepleted: boolean
  commercialEdition: boolean
  apiCapacityOption: LicenseApiCapacityOption
  expiryOption: LicenseExpiryOption
  resolveCloudLicense: () => Promise<Response>
  getLicense: () => void
}

const useLicense = (): LicenseInfoState => {
  const setHubOperational = useSetRecoilState(hubOperationalAtom)

  const [licenseRecoil, setLicenseRecoil] = useRecoilState(licenseInfoAtom)

  const [ready, setReady] = useState(false)
  const [licenseInfo, setLicenseInfo] = useState(defaultLicenseInfo)
  const [nodeLimitOverflow, setNodeLimitOverflow] = useRecoilState(
    nodeLimitOverflowAtom
  )
  const [expired, setExpired] = useState(false)
  const [valid, setValid] = useState(true)
  const [showApiCapacityProgress, setShowApiCapacityProgress] = useState(false)
  const [apiCapacityDepleted, setApiCapacityDepleted] = useState(false)
  const [commercialEdition, setCommercialEdition] = useState(false)

  const internetConnection = useRecoilValue(internetConnectionSelector)

  useEffect(() => setLicenseInfo(licenseRecoil), [licenseRecoil])

  const [apiCapacityOption, setApiCapacityOption] =
    useState<LicenseApiCapacityOption>(LicenseApiCapacityOption.Limited)
  const [expiryOption, setExpiryOption] = useState<LicenseExpiryOption>(
    LicenseExpiryOption.Ends
  )

  // API capacity & expiry behavior for each license edition
  useEffect(() => {
    switch (licenseInfo.doc.edition) {
    case LicenseCommunityEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseProEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Limited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseEnterpriseEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unset)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    case LicenseOndemandEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseProCompEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Limited)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    case LicenseProUnlEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    }
  }, [licenseInfo])

  useEffect(() => {
    if (licenseInfo.currentNodeCount === 0) {
      return
    }

    setReady(licenseInfo.currentNodeCount > 0 || !internetConnection)
    setNodeLimitOverflow(
      licenseInfo?.currentNodeCount > licenseInfo?.maxNodeCount
    )
    setExpired(
      licenseInfo.doc.end !== 0 && Date.now() > licenseInfo.doc.end
    )
    setValid(
      licenseInfo.valid &&
      licenseInfo.maxNodeCount > 0 &&
      (internetConnection || licenseInfo.doc.edition === LicenseEnterpriseEdition)
    )
    setShowApiCapacityProgress(licenseInfo.doc.initialCapacity > 0)
    setApiCapacityDepleted(licenseInfo.doc.capacity < 0)
    setCommercialEdition(
      licenseInfo.doc.edition !== '' &&
      licenseInfo.doc.edition !== LicenseCommunityEdition
    )
  }, [licenseInfo, internetConnection])

  const resolveCloudLicense = (): Promise<Response> => {
    return Utils.fetchWithTimeout(`${HubBaseUrl}/license/resolve`, { method: 'POST', timeout: 8000 })
  }

  const setHubPublicNetworkOk = useSetRecoilState(hubPublicNetworkOkAtom)

  const getLicense = () => {
    let status: number

    Utils.fetchWithTimeout(`${HubBaseUrl}/license`, { method: 'GET', timeout: 3000 })
      .then((response) => {
        status = response.status
        return response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      })
      .then((response) => response.json())
      .then((licenseInfo) => {
        setHubOperational(true)
        setLicenseRecoil(licenseInfo.license)
        setHubPublicNetworkOk(licenseInfo.networkOk)
      })
      .catch((err) => {
        setLicenseRecoil(defaultLicenseInfo)
        setHubOperational(false)
        setHubPublicNetworkOk(false)
        if (err.length > 0) {
          console.error(err)
          if (status > 400 && status !== 504 && status !== 502) {
            toast.error(err.toString(), {
              theme: 'colored'
            })
          }
        }
      })
  }

  return {
    ready,
    licenseInfo,
    nodeLimitOverflow,
    setNodeLimitOverflow,
    expired,
    valid,
    showApiCapacityProgress,
    apiCapacityDepleted,
    commercialEdition,
    apiCapacityOption,
    expiryOption,
    resolveCloudLicense,
    getLicense,
  }
}

export default useLicense
