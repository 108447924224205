import React from 'react'
import variables from '../../../variables.module.scss'
import styles from '../TrafficEntry.module.sass'
import { Box } from '@mui/material'

interface UnresolvedFieldBadgeProps {
  text?: string
}

export const UnresolvedFieldBadge: React.FC<UnresolvedFieldBadgeProps> = ({ text = 'unresolved' }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap='2px'
      padding='1px 4px'
      borderRadius='4px'
      border={`2px dashed ${variables.dataBackgroundColor}`}
      width='fit-content'
    >
      <span className={styles.DataText} style={{ color: variables.lightGrayColor }}>
        {text}
      </span>
    </Box>
  )
}
