import React from 'react'
import { resolveQuery } from '../helpers'
import { Entry, Resolution } from '../../EntryListItem/Entry'
import { EntryData } from './EntryData'
import styles from '../TrafficEntry.module.sass'
import { Box } from '@mui/material'
import { UnresolvedFieldBadge } from './UnresolvedFieldBadge'
import { useRecoilValue } from 'recoil'
import entryDetailsOpenAtom from '../../../recoil/entryDetailsOpen'

type EntryPoint = 'src' | 'dst'

export const EntrySource: EntryPoint = 'src'
export const EntryDestination: EntryPoint = 'dst'

interface ResolutionDataProps {
  entry: Entry,
  point: EntryPoint
  showLongNames: boolean
}

export const ResolutionData: React.FC<ResolutionDataProps> = ({ entry, point }) => {
  const entryDetailsOpen = useRecoilValue(entryDetailsOpenAtom)

  const resolution: Resolution = entry[point]

  const entryData = {
    serviceName: resolution.service?.metadata?.name ? resolution.service?.metadata?.name : '-',
    podName: resolution.pod?.metadata?.name ? resolution.pod?.metadata?.name : resolution?.name ? resolution?.name : '-',
    podIp: resolution.ip ? resolution.ip : '-',
    podPort: resolution.port ? resolution.port : '-',
  }

  const resolvePodNameQueryLeftOperand = () => {
    if (resolution?.pod?.metadata?.name) {
      return `${point}.pod.metadata.name`
    }

    if (resolution?.name) {
      return `${point}.name`
    }

    return ''
  }

  return entryData.serviceName !== '-' || entryData.podName !== '-' ? (
    <Box display='flex' alignItems='center' gap='3px'>
      {entryData.serviceName !== '-' && <EntryData
        name={entryData.serviceName}
        query={resolveQuery(`${point}.service.metadata.name`, '==', entryData.serviceName)}
      />}
      {entryData.podName !== '-' && (!entryDetailsOpen || entryData.serviceName === '-') && (
        <>
          {entryData.serviceName !== '-' && <span className={styles.DataText}>/</span>}
          <EntryData
            name={entryData.podName}
            query={resolveQuery(resolvePodNameQueryLeftOperand(), '==', entryData.podName)}
          />
        </>
      )}
    </Box>
  ) : (
    <UnresolvedFieldBadge />
  )
}
