import Intercom from '@intercom/messenger-js-sdk'
import { getAuthSessionDetails } from '../components/UI/Auth/AuthSessionDetails'
import { appAuthEnabled, appOidcAuthTypeEnabled, appSamlAuthTypeEnabled, appSupportChatEnabled } from '../types/global'
import authSessionOkAtom from '../recoil/authSessionOk'
import { useRecoilValue } from 'recoil'
import { getSamlAttributesPayload } from '../components/UI/Auth/SamlAuth/Attributes'
import { extractJwtPayload } from '../components/UI/Auth/SamlAuth/SamlAuth'
import { useEffect } from 'react'

export const useIntercomChat = () => {
  const authSessionOk= useRecoilValue(authSessionOkAtom)

  const user = {
    user_id: null,
    name: 'Guest user (name not set, auth type: none)',
    email: '(no email)',
    created_at: Date.now()
  }

  if (appAuthEnabled()) {
    if (appOidcAuthTypeEnabled()) {
      if (authSessionOk) {
        const sessionDetails = getAuthSessionDetails()
        user.user_id = sessionDetails.userId
        user.email = sessionDetails.email
        user.name = sessionDetails.name
        user.created_at = sessionDetails.createdTime
      } else {
        user.name = 'Guest user (name not set, auth type: OIDC)'
        user.email = '(no email)'
      }
    } else if (appSamlAuthTypeEnabled()) {
      if (authSessionOk) {
        const jwtPayload = extractJwtPayload() ?? []
        if (jwtPayload['sub'] !== undefined) {
          user.user_id = jwtPayload['sub']
        }

        const samlAttributes = getSamlAttributesPayload()
        if (samlAttributes['email'] !== undefined) {
          user.name = `SAML user (${samlAttributes['email']})`
          user.email = samlAttributes['email']
        } else {
          user.name = 'User (name not set, auth type: SAML)'
          user.email = 'User (email not set, auth type: SAML)'
        }
      } else {
        user.name = 'Guest user (name not set, auth type: SAML)'
        user.email = '(no email)'
      }
    }
  }

  useEffect(() => {
    if (!appSupportChatEnabled()) {
      return
    }

    Intercom({
      app_id: 'ulyaoqvr',
      ...user
    });
  }, [])
}
