import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Utils } from '../../../helpers/Utils';
import { useInterval } from '../../../helpers/interval';

import styles from "./TrafficStatsFooter.module.sass";

import { Box, Tooltip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import variables from '../../../variables.module.scss'

import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import timezoneModalOpenAtom from '../../../recoil/timezoneModalOpen/atom';
import timezoneAtom from '../../../recoil/timezone/atom';
import { LicenseInfoModal } from '../../modals/LicenseInfoModal/LicenseInfoModal';
import useTrafficStats from '../../../hooks/useTrafficStats';

const CircleDivider: React.FC = () => {
  return <CircleIcon htmlColor={variables.lightBlueColor} sx={{ fontSize: '8px' }} />
}

export const TrafficStatsFooter: React.FC = () => {
  const timezone = useRecoilValue(timezoneAtom)
  const [momentTimeNow, setMomentTimeNow] = React.useState(Utils.TimezoneMoment(timezone));

  const {entriesCaptured, sizeCaptured} = useTrafficStats()

  const setTimezoneModalOpen = useSetRecoilState(timezoneModalOpenAtom)

  useInterval(async () => {
    setMomentTimeNow(Utils.TimezoneMoment(timezone));
  }, 1000, true);

  const handleOpenTimezoneModal = () => setTimezoneModalOpen(true)

  return (
    <Box>
      <div className={styles.TrafficStatsFooter}>
        <Box display='flex' alignItems='center' gap='8px'>
          <span style={{ fontSize: '14px' }}>
            Captured items: <strong>{entriesCaptured}</strong>
          </span>
          <CircleDivider />
          <span style={{ fontSize: '14px' }}>
            Total captured size: <strong>{sizeCaptured}</strong>
          </span>
          <CircleDivider />
          <Tooltip arrow title={<Box display='flex' alignItems='center' gap='5px'><AccessTimeFilledRoundedIcon sx={{ fontSize: '14px' }}/><span>Click to change timezone</span></Box>}>
            <div style={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleOpenTimezoneModal}>
              {momentTimeNow.format('MM/DD/YYYY, h:mm:ss A UTCZ')}
            </div>
          </Tooltip>
        </Box>
        <Box display='flex' alignItems='center' gap='8px'>
          <LicenseInfoModal />
        </Box>
      </div>
    </Box>
  )
}
