import { HealthStatus } from '../../types/health'
import frontHealthAtom from './frontHealth'
import { selector } from 'recoil'
import hubHealthAtom from './hubHealth'

export const healthStatusSelector = selector({
  key: 'healthStatus',
  get: ({ get }): HealthStatus => {
    const frontHealth = get(frontHealthAtom)
    const hubHealth = get(hubHealthAtom)

    return {
      front: frontHealth,
      hub: hubHealth,
      healthy: frontHealth?.healthy && hubHealth?.healthy
    }
  }
})

export const internetConnectionSelector = selector({
  key: 'internetConnection',
  get: ({ get }): boolean => {
    const frontHealth = get(frontHealthAtom)
    const hubHealth = get(hubHealthAtom)

    const frontInternetOk = frontHealth?.internet?.ok || !frontHealth?.internet?.required
    const hubOperational = hubHealth?.operational 
    const hubInternetOk = hubHealth?.internet?.ok || !hubHealth?.internet?.required

    if (
      frontInternetOk === null || frontInternetOk === undefined ||
      hubOperational === null || hubOperational === undefined ||
      hubInternetOk === null || hubInternetOk === undefined
    ) {
      return true
    }

    return frontInternetOk && hubOperational && hubInternetOk
  }
})
