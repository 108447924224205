import React from 'react'

import { Box } from '@mui/material'
import Queryable from '../../UI/Queryable/Queryable'

import styles from '../TrafficEntry.module.sass'
import { UnresolvedFieldBadge } from './UnresolvedFieldBadge'

export type ResourceType = 'name' | 'node' | 'namespace' | 'service' | 'pod'

interface EntryDataProps {
  resource?: ResourceType
  name: string
  query: string
  tooltipStyle?: React.CSSProperties
  tooltipIconStyle?: React.CSSProperties
  extraName?: string
  extraQuery?: string
  unresolvedText?: string
}

export const EntryData: React.FC<EntryDataProps> = ({
  name,
  query,
  tooltipStyle,
  tooltipIconStyle,
  extraName,
  extraQuery,
  unresolvedText = 'unresolved'
}) => {
  return (
    <Box display='flex' alignItems='center'>
      {name && name !== '-' ? (<Queryable
        query={query}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          transform: 'translate(-5px, -10px)',
          ...tooltipIconStyle
        }}
        tooltipStyle={{
          transform: 'translate(15px, -15px)',
          ...tooltipStyle
        }}
      >
        <span className={styles.DataText}>{name}</span>
      </Queryable>) : (
        <Queryable
          query={query}
          displayIconOnMouseOver={true}
          flipped={true}
          style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
          iconStyle={{
            transform: 'translate(-5px, -10px)',
            ...tooltipIconStyle
          }}
          tooltipStyle={{
            transform: 'translate(15px, -15px)',
            ...tooltipStyle
          }}
        >
          <UnresolvedFieldBadge text={unresolvedText} />
        </Queryable>
      )}
      {extraName && extraName !== '-' && <Queryable
        query={extraQuery}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          position: 'absolute',
          top: '12px'
        }}
      >
        <span className={styles.DataText}>
          {name && name !== '-' ? <>&nbsp;({extraName})</> : <>&nbsp;{extraName}</>}
        </span>
      </Queryable>}
    </Box>
  )
}
