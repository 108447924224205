import useLicense from './useLicense'
import { useEffect } from 'react'
import streamingHealthAtom from '../recoil/streamingHealth'
import { useRecoilValue } from 'recoil'
import { appSupportChatEnabled } from '../types/global'
import usePageRefreshObserver from './usePageRefreshObserver'

const STREAMING_HEALTH_REPORT_COUNT_THRESHOLD = 5

export const useIntercomEvents = () => {
  const { valid, ready, licenseInfo } = useLicense()

  if (!appSupportChatEnabled()) {
    return
  }

  useEffect(() => {
    if (!valid) {
      if (ready) {
        window.Intercom('trackEvent', 'license-invalid', licenseInfo.doc)
      }
      else {
        window.Intercom('trackEvent', 'license-invalid',{ready: ready})
      }
    }
  }, [valid])

  const streamingHealth = useRecoilValue(streamingHealthAtom)

  useEffect(() => {
    if (streamingHealth === null) {
      return
    }

    if (!streamingHealth.state && streamingHealth.count === STREAMING_HEALTH_REPORT_COUNT_THRESHOLD) {
      window.Intercom('trackEvent', 'streaming-error', { streamingHealth: streamingHealth.metadata, info: licenseInfo.doc})
    }
  }, [streamingHealth])

  const isRefreshed = usePageRefreshObserver()

  useEffect(() => {
    if (isRefreshed) {
      if (ready) {
        window.Intercom('trackEvent', 'page-refreshed', licenseInfo.doc)
      }
      else {
        window.Intercom('trackEvent', 'page-refreshed', {ready: ready})
      }
    }
  }, [isRefreshed])
}
