import style from './StatusBar.module.sass';
import React, { useEffect, useRef, useState } from "react";
import Queryable from "../Queryable/Queryable";
import { HubBaseUrl } from "../../../consts";

import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../../../recoil/trafficPlayback/atom'
import appliedPodRegexAtom from '../../../recoil/appliedPodRegex/atom';
import appliedPodNamespacesAtom from '../../../recoil/appliedPodNamespaces/atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import variables from '../../../variables.module.scss';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import StorageIcon from '@mui/icons-material/Storage';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import PasswordIcon from '@mui/icons-material/Password';
import DataArrayIcon from '@mui/icons-material/DataArray';
import MemoryIcon from '@mui/icons-material/Memory';

import { Box, ClickAwayListener, Fade, Grow, IconButton, Popper, Tooltip, Typography } from '@mui/material';
import { Dropdown } from '@mui/base'

import { toast } from "react-toastify";

import { PodTargetSetting } from './PodTargetSetting/PodTargetSetting';
import { appBpfOverrideDisabled, checkTargetedPodsUpdateDisabled } from '../../../types/global'
import { useAuth } from '../Auth/Auth';
import targetedNodesAtom from '../../../recoil/targetedNodes';
import { Node } from '../../../types/resources';
import appliedPodBpfOverrideAtom from '../../../recoil/appliedPodBpfOverride'
import { TargetSettingDisabledCard } from './PodTargetSetting/TargetSettingDisabledCard'
import { InlineCode } from '../InlineCode/InlineCode'

const pluralize = (noun: string, amount: number) => {
  return `${noun}${amount !== 1 ? 's' : ''}`
}

const uniqueNamespaces = (targets: Target[]) => {
  return [...new Set(targets.map(pod => `[${pod.namespace}]`))];
}

const uniqueNodes = (targets: Target[]) => {
  const uniqueNodeStrings = [...new Set(targets.map((pod): string => JSON.stringify(pod.node)))]
  return uniqueNodeStrings.map((nodeStr): Node => JSON.parse(nodeStr))
}

export interface Target {
  name: string;
  namespace: string;
  node: Node;
}

interface StatusBarContentProps {
  expandedBar: boolean;
  setExpandedBar: (v: boolean) => void;
  targets: Target[];
  fetchTargetedPods: () => void;
}

const popperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, -40]
    }
  }
]

const StatusBarContent: React.FC<StatusBarContentProps> = ({
  expandedBar,
  setExpandedBar,
  targets,
  fetchTargetedPods
}) => {
  const [podTargetDropdownOpen, setPodTargetDropdownOpen] = React.useState(false)
  const [podTargetDropdownHovered, setPodTargetDropdownHovered] = React.useState(false)

  const setAppliedPodRegex = useSetRecoilState(appliedPodRegexAtom)
  const setAppliedPodNamespaces = useSetRecoilState(appliedPodNamespacesAtom)

  const [podRegex, setPodRegex] = useRecoilState(appliedPodRegexAtom)
  const [podNamespaces, setPodNamespaces] = useRecoilState(appliedPodNamespacesAtom)
  const [podBpfOverride, setPodBpfOverride] = useRecoilState(appliedPodBpfOverrideAtom)

  const [podRegexChanging, setPodRegexChanging] = React.useState(false)
  const [podNamespacesChanging, setPodNamespacesChanging] = React.useState(false)
  const [podBpfOverrideChanging, setPodBpfOverrideChanging] = React.useState(false)

  const [podRegexActive, setPodRegexActive] = React.useState(false)
  const [podNamespacesActive, setPodNamespacesActive] = React.useState(false)
  const [podBpfOverrideActive, setPodBpfOverrideActive] = React.useState(false)

  const [podRegexError, setPodRegexError] = React.useState(false)
  const [podNamespacesError, setPodNamespacesError] = React.useState(false)
  const [podBpfOverrideError, setPodBpfOverrideError] = React.useState(false)

  const [targetedNodes, setTargetedNodes] = useRecoilState(targetedNodesAtom)

  useEffect(() => {
    setTargetedNodes(uniqueNodes(targets))
  }, [targets])

  const anchorRef = useRef(null)

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const handlePodTargetDropdownOpen = () => {
    if (!checkTargetedPodsUpdateDisabled()) {
      setPodTargetDropdownOpen(!podTargetDropdownOpen)
    }
  }

  useEffect(() => {
    if (!expandedBar && podTargetDropdownHovered) {
      return
    }

    if (!checkTargetedPodsUpdateDisabled()) {
      setPodTargetDropdownOpen(expandedBar)
    }
  }, [expandedBar])

  const handlePodTargetDropdownOnMouseOver = () => setPodTargetDropdownHovered(true)
  const handlePodTargetDropdownOnMouseLeave = () => setPodTargetDropdownHovered(false)

  const fetchTargetSettings = () => {
    fetch(`${HubBaseUrl}/pods/target/settings`)
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .then(data => {
        const regex = data.regex.trim()

        setPodRegex(regex)
        setPodRegexActive(regex.length > 0)

        const namespaces = data.namespaces.filter((value) => value.length > 0)

        setPodNamespaces(namespaces)
        setPodNamespacesActive(namespaces.length > 0)

        const bpfOverride = data.bpfOverride.trim()

        setPodBpfOverride(bpfOverride)
        setPodBpfOverrideActive(bpfOverride.length > 0)
      })
      .catch(err => {
        if (err.length > 0) {
          console.error(err)
        }
      });
  }

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetSettings()
  }, [trafficPlayback, shouldAuthenticate])

  const handlePodRegexChange = (regex: string) => {
    setPodRegex(regex)
  }

  const handlePodNamespacesChange = (namespacesStr: string) => {
    const namespaces = namespacesStr.trim().split(/\s*,\s*/)
    setPodNamespaces(namespaces)
  }

  const handleBpfOverrideChange = (bpf: string) => {
    setPodBpfOverride(bpf)
  }

  const postPodRegex = () => {
    fetch(
      `${HubBaseUrl}/pods/target/regex`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({regex: podRegex.trim()})
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        if (response.status === 200) {
          setPodRegexError(false)
          setPodRegexActive(true)
          setAppliedPodRegex(podRegex.trim())
          fetchTargetedPods()
        }
      })
      .catch(() => {
        toast.error(`Invalid pod regex! Check syntax & retry.`, {
          theme: 'colored'
        });
        setPodRegexError(true)
      }).finally(() => setPodRegexChanging(false));
  }

  const postPodNamespaces = () => {
    const namespaces = podNamespaces.filter(value => value.trim())

    fetch(
      `${HubBaseUrl}/pods/target/namespaces`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({namespaces})
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        if (response.status === 200) {
          setPodNamespacesError(false)
          setPodNamespacesActive(true)
          setAppliedPodNamespaces(namespaces)
          fetchTargetedPods()
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(`Invalid pod namespaces! ${err.toString()}`, {
          theme: 'colored'
        });
        setPodNamespacesError(true)
      }).finally(() => setPodNamespacesChanging(false));
  }

  const postBpfOverride = () => {
    fetch(
      `${HubBaseUrl}/pods/target/bpf`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({bpf: podBpfOverride.trim()})
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        if (response.status === 200) {
          setPodBpfOverrideError(false)
          setPodBpfOverrideActive(true)
          setPodBpfOverride(podBpfOverride)
          fetchTargetedPods()
        }
      })
      .catch(() => {
        toast.error(`Invalid pod regex! Check syntax & retry.`, {
          theme: 'colored'
        });
        setPodBpfOverrideError(true)
      }).finally(() => setPodBpfOverrideChanging(false));
  }

  return (
    <div className={style.statusBarContainer} onMouseOver={() => setExpandedBar(true)} onMouseLeave={() => setExpandedBar(false)}>
      <div className={`${style.statusBar} ${(expandedBar && targets.length > 0 ? `${style.expandedStatusBar}` : "")}`} data-cy="expandedStatusBar">
        <div className={style.podsCount}>
          {targets.length > 0 && <span className={style.podsCountText} data-cy="podsCountText">
            {`
              Targeting ${targets.length} ${pluralize('pod', targets.length)}
              ${targets.length ? "in" : ""}
              ${targets.length ? pluralize('namespace', uniqueNamespaces(targets).length) : ""}
              ${uniqueNamespaces(targets).join(", ")},
              observing ${targetedNodes.length} ${pluralize('node', targetedNodes.length)}
            `}
          </span>}
          {targets.length === 0 && <span className={style.podsCountText}>
            No pods to target by current regex/namespaces
          </span>}
        </div>
        {expandedBar && targets.length > 0 && <div className={style.tableContainer}>
          <table>
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Pod name</th>
                <th style={{ width: "30%" }}>Namespace</th>
              </tr>
            </thead>
            <tbody>
              {targets.map(pod => <tr key={pod.name}>
                <td style={{ position: "relative", width: "70%" }}>
                  <Queryable
                    query={`src.pod.metadata.name == "${pod.name}" or dst.pod.metadata.name == "${pod.name}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.name}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "30%" }}>
                  <Queryable
                    query={`src.namespace == "${pod.namespace}" or dst.namespace == "${pod.namespace}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {pod.namespace}
                  </Queryable>
                </td>
              </tr>)}
            </tbody>
          </table>
          <hr />
          <table>
            <thead>
              <tr>
                <th style={{ width: "70%" }}>Node name</th>
                <th style={{ width: "30%" }}>Node IP</th>
              </tr>
            </thead>
            <tbody>
              {targetedNodes.map(node => <tr key={node.name}>
                <td style={{ position: "relative", width: "70%" }}>
                  <Queryable
                    query={`node.name == "${node.name}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {node.name}
                  </Queryable>
                </td>
                <td style={{ position: "relative", width: "30%" }}>
                  <Queryable
                    query={`node.ip == "${node.ip}"`}
                    displayIconOnMouseOver={true}
                    flipped={true}
                    iconStyle={{ position: "absolute", left: "0", marginTop: "-25px" }}
                    tooltipStyle={{ marginLeft: "25px" }}
                  >
                    {node.ip}
                  </Queryable>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>}
      </div>
      <ClickAwayListener
        onClickAway={() =>
          !expandedBar && podTargetDropdownOpen && setPodTargetDropdownOpen(false)
        }
      >
        <div
          style={{
            position: 'relative',
            marginTop: '2px',
            backgroundColor: variables.dataBackgroundColor,
            borderRadius: '0 0 6px 0'
          }}
        >
          <Dropdown open={podTargetDropdownOpen}>
            <Tooltip open={false} title='Target pods by regex/namespaces' arrow>
              <IconButton color='primary' ref={anchorRef} onClick={handlePodTargetDropdownOpen}
                disabled={checkTargetedPodsUpdateDisabled()}>
                <ViewInArRoundedIcon
                  fontSize='small'
                  htmlColor={checkTargetedPodsUpdateDisabled() ? variables.lightGrayColor : variables.blueColor}
                />
              </IconButton>
            </Tooltip>
            <Popper
              open={podTargetDropdownOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement='bottom-start'
              transition
              modifiers={popperModifiers}
              sx={{ zIndex: 1300 }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left top'
                  }}
                >
                  <Box
                    width='260px'
                    padding='12px'
                    borderRadius='8px'
                    boxShadow={variables.lightShadow}
                    bgcolor={variables.dataBackgroundColor}
                    onMouseOver={handlePodTargetDropdownOnMouseOver}
                    onMouseLeave={handlePodTargetDropdownOnMouseLeave}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      marginBottom='15px'
                    >
                      <Box display='flex' alignItems='center' gap='10px'>
                        <ViewInArRoundedIcon htmlColor={variables.blueColor} />
                        <Typography
                          variant='h6'
                          fontFamily={variables.textFontFamily}
                          color={variables.blueColor}
                          fontWeight={700}
                        >
                          Pod Targeting
                        </Typography>
                      </Box>
                      <Tooltip title='Learn how pod targeting works'>
                        <IconButton
                          size='small'
                          onClick={() => window.open('https://docs.kubeshark.co/en/pod_targeting', '_blank')}
                        >
                          <HelpRoundedIcon
                            fontSize='small'
                            htmlColor={variables.lightGrayColor}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      gap='10px'
                      padding='8px'
                      borderRadius='6px'
                      bgcolor={variables.mainBackgroundColor}
                      mb='10px'
                    >
                      <StorageIcon htmlColor={variables.grayColor} />
                      <Typography variant='body2' fontFamily={variables.textFontFamily} fontWeight={500} color={variables.fontColor}>
                        Applied rules are cluster-wide.
                      </Typography>
                    </Box>
                    <PodTargetSetting
                      icon={<PasswordIcon htmlColor={variables.grayColor} />}
                      title='By regex'
                      placeholder='Pod name regex'
                      handleValueChange={handlePodRegexChange}
                      value={podRegex}
                      changing={podRegexChanging}
                      setChanging={setPodRegexChanging}
                      active={podRegexActive}
                      error={podRegexError}
                      postValue={postPodRegex}
                    />
                    <PodTargetSetting
                      icon={<DataArrayIcon htmlColor={variables.grayColor} />}
                      title='By namespaces'
                      placeholder='namespace1,namespace2'
                      handleValueChange={handlePodNamespacesChange}
                      value={podNamespaces.filter(value => value.trim()).length === 0 ? '' : podNamespaces}
                      changing={podNamespacesChanging}
                      setChanging={setPodNamespacesChanging}
                      active={podNamespacesActive}
                      error={podNamespacesError}
                      postValue={postPodNamespaces}
                    />
                    <Box
                      position='relative'
                      height='100%'
                      width='100%'
                      boxSizing='border-box'
                    >
                      {appBpfOverrideDisabled() && (
                        <TargetSettingDisabledCard>
                          <span
                            style={{
                              width: '100%',
                              boxSizing: 'border-box',
                              fontFamily: variables.textFontFamily,
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: 2,
                              color: variables.fontColor
                            }}
                          >
                            Set&nbsp;<InlineCode>tap.packetCapture</InlineCode>&nbsp;
                            to&nbsp;<InlineCode>af_packet</InlineCode>
                            &nbsp;to use BPF override
                          </span>
                        </TargetSettingDisabledCard>
                      )}
                      {!appBpfOverrideDisabled() && <PodTargetSetting
                        icon={<MemoryIcon htmlColor={variables.grayColor} />}
                        title='By BPF override'
                        placeholder='Enter BPF string'
                        handleValueChange={handleBpfOverrideChange}
                        value={podBpfOverride}
                        changing={podBpfOverrideChanging}
                        setChanging={setPodBpfOverrideChanging}
                        active={podBpfOverrideActive}
                        error={podBpfOverrideError}
                        postValue={postBpfOverride}
                      />}
                    </Box>
                  </Box>
                </Grow>
              )}
            </Popper>
          </Dropdown>
        </div>
      </ClickAwayListener>
    </div>
  )
}

interface StatusBarProps {
  targets: Target[];
  setTargets: React.Dispatch<React.SetStateAction<Target[]>>;
}

export const StatusBar: React.FC<StatusBarProps> = ({
  targets,
  setTargets,
}) => {
  const [expandedBar, setExpandedBar] = useState(false);
  const [targetedPodsFetched, setTargetedPodsFetched] = useState(false)
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const fetchTargetedPods = () => {
    fetch(`${HubBaseUrl}/pods/targeted`)
      .then(response => response.text())
      .then(rawJson => rawJson ? JSON.parse(rawJson) : {})
      .then(data => setTargets(data.targets ?? []))
      .catch(err => console.error(err)).finally(() => setTargetedPodsFetched(true));
  }

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchTargetedPods()

    const fetchInterval = setInterval(() => {
      if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
        return
      }

      if (shouldAuthenticate) {
        return
      }

      fetchTargetedPods()
    }, 5000)

    return () => clearInterval(fetchInterval)
  }, [trafficPlayback, shouldAuthenticate])

  return (
    <Fade in={targetedPodsFetched}>
      <Box>
        <StatusBarContent
          expandedBar={expandedBar}
          setExpandedBar={setExpandedBar}
          targets={targets}
          fetchTargetedPods={fetchTargetedPods}
        />
      </Box>
    </Fade>
  );
}
