import React from 'react'
import Queryable from '../../UI/Queryable/Queryable'

import miscStyles from '../misc.module.sass'
import variables from '../../../variables.module.scss'
import { Box } from '@mui/material'

interface MethodProps {
  name: string
  query: string
}

export const Method: React.FC<MethodProps> = ({ name, query }) => {
  if (!name) {
    return <></>
  }

  return (
    <Box>
      <Queryable
        query={query}
        className={`${miscStyles.protocol} ${miscStyles.method}`}
        displayIconOnMouseOver={true}
        style={{ whiteSpace: 'nowrap' }}
        flipped={true}
        iconStyle={{
          transform: 'translate(-18px, 0)',
        }}
        tooltipStyle={{
          transform: 'translate(0, -15px)',
          fontFamily: variables.textFontFamily
        }}
      >
        {name}
      </Queryable>
    </Box>
  )
}
