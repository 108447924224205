import React, { useEffect, useState } from 'react'
import { Entry } from '../../EntryListItem/Entry'
import Queryable from '../../UI/Queryable/Queryable'

import variables from '../../../variables.module.scss'
import { Box } from '@mui/material'

interface DuplicateBadgeProps {
  duplicate: boolean
  duplicateOrigin: Entry
}

export const DuplicateBadge: React.FC<DuplicateBadgeProps> = ({ duplicate, duplicateOrigin }) => {
  if (!duplicate) {
    return <></>
  }

  const [originQuery, setOriginQuery] = useState('')

  useEffect(() => {
    if (duplicateOrigin === null) {
      return
    }

    const ipWithoutPort = duplicateOrigin?.worker.replace(/:\d+/, '')
    setOriginQuery(`node.ip == "${ipWithoutPort}" and stream == "${duplicateOrigin?.stream}"`)
  }, [])

  return (
    <Box
      height='16px'
      padding='4px 6px'
      display='flex'
      alignItems='center'
      gap='4px'
      boxSizing='border-box'
      borderRadius='4px'
      boxShadow={variables.lightWhiteShadow}
      bgcolor={variables.lightGrayColorAlt}
    >
      <Queryable
        query={originQuery}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          transform: 'translate(-5px, -10px)',
        }}
        tooltipStyle={{
          transform: 'translate(15px, -20px)',
        }}
      >
        <span style={{
          fontSize: '10px',
          fontWeight: 600,
          fontFamily: 'Roboto',
          color: '#ffffff'
        }}>
          dup
        </span>
      </Queryable>
    </Box>
  )
}
